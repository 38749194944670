import React from 'react';
import Head from 'next/head';

export type CustomMetaDataProps = {
  title?: string;
  description?: string;
  image?: string;
};

const CustomMetaData = (props: CustomMetaDataProps) => {
  const { title, description, image } = props;

  // const subPageTitle = `${titleDefault}|${SITE_TITLE}`;
  // const newTitle = `${title ? title : titleDefault ? subPageTitle : SITE_TITLE}`;
  // const newTitle = `${title ? title : titleDefault ? subPageTitle : SITE_TITLE}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="twitter:title" property="og:title" content={title} key="title" />
      {description && <meta name="description" content={description} key="description" />}
      {description && <meta name="twitter:description" property="og:description" content={description} key="description2" />}
      {image && <meta name="twitter:image" property="og:image" content={image} key="image" />}
      <meta name="twitter:card" content="summary_large_image" key="card" />
    </Head>
  );
};

export default CustomMetaData;
